import styles from "./stars.module.scss";
import { FC } from "react";
import { StarActiveIcon, StarIcon, StarIconHalfIcon } from "../icon";
import classNames from "classnames";

interface IStars {
  stars: number | string;
  className?: string;
}

const isFractionGreaterThanHalf = (number: number) => {
  const fractionalPart = number % 0.999;
  return fractionalPart > 0.2;
};

export const Stars: FC<IStars> = ({ stars, className }) => {
  const wrapClassnames = classNames({
    [className!]: className,
    [styles.wrap]: true,
  });

  const renderStars = (stars: number, pos: number) => {
    if (stars > pos) {
      if (
        isFractionGreaterThanHalf(stars) &&
        stars.toFixed() === pos.toString()
      ) {
        return <StarIconHalfIcon />;
      } else {
        return <StarActiveIcon />;
      }
    } else {
      return <StarIcon />;
    }
  };

  return (
    <div className={wrapClassnames}>
      {renderStars(Number(stars), 0)}
      {renderStars(Number(stars), 1)}
      {renderStars(Number(stars), 2)}
      {renderStars(Number(stars), 3)}
      {renderStars(Number(stars), 4)}
    </div>
  );
};
