"use client";
import {FC} from "react";
import {Swiper, SwiperSlide} from "swiper/react";
import {CardMini} from "../card-mini/card-mini";
import {Navigation, Pagination} from "swiper/modules";
import "swiper/scss";
import "swiper/scss/navigation";
import "swiper/scss/pagination";
import "./swiper-collection.scss";
import {ICardMini} from "@/services/types/types";

interface ISwiperCollection {
  items: Array<ICardMini>;
  slidesPerView?: number;
  className?: string;
  onClick?: () => void;
}

export const SwiperCollection: FC<ISwiperCollection> = ({
                                                          items,
                                                          slidesPerView,
                                                          className,
                                                          onClick,
                                                        }) => {
  const breakpoints =
    !slidesPerView || slidesPerView === 6
      ? {
        1559: {
          slidesPerView: 6,
        },
        1366: {
          slidesPerView: 5,
        },
        1023: {
          slidesPerView: 4,
          allowTouchMove: true,
        },
        600: {
          slidesPerView: 3,
          allowTouchMove: true,
        },
        320: {
          spaceBetween: 12,
          slidesPerView: 2,
          allowTouchMove: true,
        },
      }
      : {
        1559: {
          slidesPerView: 5,
        },
        1023: {
          slidesPerView: 4,
        },
        767: {
          slidesPerView: 3,
          allowTouchMove: true,
        },
        600: {
          slidesPerView: 3,
          allowTouchMove: true,
        },
        320: {
          slidesPerView: 2,
          allowTouchMove: true,
          spaceBetween: 12,
        },
      };
  return (
    <Swiper
      className={"swiper-collection"}
      spaceBetween={16}
      allowTouchMove={false}
      slidesPerView={slidesPerView ? slidesPerView : 6}
      modules={[Navigation, Pagination]}
      pagination={{
        clickable: true,
        dynamicBullets: true,
        dynamicMainBullets: 2,
      }}
      navigation
      loop={false}
      breakpoints={breakpoints}
    >
      {items.map((item: ICardMini, index: number) => {
        return (
          <SwiperSlide
            key={`${index + "swiperSlidekey" + "swiperCollection" + item.id}`}
          >
            <CardMini card={item} className={className} onClick={onClick}/>
          </SwiperSlide>
        );
      })}
    </Swiper>
  );
};
