import { ICardMini } from "@/services/types/types";
import styles from "./card-mini-buttons.module.scss";
import { FC, useEffect, useState } from "react";
import { AddToCartIcon, InCartIcon, NotAvailableIcon } from "@/components/icon";
import Link from "next/link";
import { useAppDispatch, useAppSelector } from "@/services/redux/hooks";
import { addToCart, getUserInfo } from "@/services/redux/features/userSlice";
import { usePathname, useRouter } from "next/navigation";
import {
  clearItemsInfo,
  getCustomerInfo,
  setCustomerInfo,
} from "@/services/redux/features/orderSlice";

interface ICardMiniButtons {
  card: ICardMini;
  onClickAddToCart?: () => void;
}

export const CardMiniButtons: FC<ICardMiniButtons> = ({
  card,
  onClickAddToCart,
}) => {
  const pathname = usePathname();
  const [isClient, setIsClient] = useState(false);
  const [buttonDisable, setButtonDisable] = useState<boolean>(false);
  const currentStep = useAppSelector((state) => state.order.step);
  const customer = useAppSelector(getCustomerInfo);
  const router = useRouter();
  const user = useAppSelector(getUserInfo);
  const dispatch = useAppDispatch();
  const isInCart = user.info.cart.find(
    (item: any) => item.product_id === card.id
  );
  const handleCartClick = (e: any) => {
    e.preventDefault();
    if (isInCart) {
      router.push("/cart");
    } else {
      if (onClickAddToCart) {
        onClickAddToCart();
      }
      if (pathname === "/search/" && !isInCart)
        ym(42989679, "reachGoal", "addToCartFromBigSearch");
      setButtonDisable(true);
      const values = {
        name: customer.name ? customer.name : user.info.name,
        last_name: customer.last_name ? customer.last_name : user.info.lastname,
        tel: customer.tel ? customer.tel : user.info.phone,
        email: customer.email ? customer.email : user.info.email,
      };
      if (currentStep >= 2) {
        dispatch(setCustomerInfo({ step: 1, ...values }));
      }

      dispatch(
        addToCart({
          id: card.id,
          quantity: card.diff_pack ? Number(card.diff_pack.value) : 1,
          price: card.price_discount,
          // isChecked: true,
        })
      );
      dispatch(clearItemsInfo());
    }
  };
  useEffect(() => setIsClient(true), []);
  return (
    <div className={styles.buttonWrap}>
      {!card.comission ? (
        isClient ? (
          card.available ? (
            isInCart ? (
              <button onClick={handleCartClick} className={styles.inCart}>
                <InCartIcon />
              </button>
            ) : (
              <button
                onClick={handleCartClick}
                className={styles.addToCart}
                disabled={buttonDisable}
                title={`Купить ${card.name}`}
              >
                <AddToCartIcon />
              </button>
            )
          ) : (
            <button className={styles.isNotAvailable} disabled>
              <NotAvailableIcon />
            </button>
          )
        ) : (
          <button
            onClick={handleCartClick}
            className={styles.addToCart}
            disabled={buttonDisable}
            title={`Купить ${card.name}`}
          >
            <AddToCartIcon />
          </button>
        )
      ) : (
        <Link
          prefetch={false}
          href={`/card/${card.id}`}
          className={styles.showButton}
        >
          Посмотреть
        </Link>
      )}
    </div>
  );
};
