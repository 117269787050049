"use client";
import { FavouriteIcon } from "@/components/icon";
import styles from "./card-mini-actions.module.scss";
import { FC, useState } from "react";
import classNames from "classnames";
import { useAppDispatch, useAppSelector } from "@/services/redux/hooks";
import {
  addToFavourites,
  getIsNewUser,
  getUserInfo,
} from "@/services/redux/features/userSlice";
import { AuthPopup } from "@/components/popups";
import { useOpenModal } from "@/services/hooks/useOpenModal";
import { ICardMini } from "@/services/types/types";
import { usePathname } from "next/navigation";

interface ICardMiniActions {
  card: ICardMini;
  onClickFavourite?: () => void;
}

export const CardMiniActions: FC<ICardMiniActions> = ({
  card,
  onClickFavourite,
}) => {
  const pathname = usePathname();
  const [showModal, handleOpenModal, handleCloseModal] = useOpenModal();
  const [isFavorites, setIsFavorites] = useState<boolean>(false);
  const dispatch = useAppDispatch();
  const user = useAppSelector(getUserInfo);
  const isNewUser = useAppSelector(getIsNewUser);

  const favouriteClassnames = classNames({
    [styles.favouriteIcon]: true,
    [styles.favouriteIconActive]: isFavorites,
  });

  setTimeout(() => {
    setIsFavorites(user.info.favourites.includes(Number(card.id)));
  }, 100);

  const handleFavouriteClick = () => {
    if (!user.auth.isAuth) {
      handleOpenModal();
    } else {
      if (onClickFavourite && !isFavorites) onClickFavourite();
      if (pathname === "/search/" && !isFavorites)
        ym(42989679, "reachGoal", "addToWishlistFromBigSearch");
      dispatch(
        addToFavourites({ id: Number(card.id), price: card.price_discount })
      );
    }
  };
  return (
    !card.comission && (
      <>
        {showModal && (
          <AuthPopup isOpen={showModal} onClose={handleCloseModal} />
        )}
        {/* {isNewUser && (
          <WelcomeBonusPopup />
        )} */}
        <div className={styles.actionWrap}>
          <button
            onClick={handleFavouriteClick}
            className={styles.favourite}
            title={`Добавить в избранное ${card.name}`}
          >
            <FavouriteIcon className={favouriteClassnames} color="#9ea0a3" />
          </button>
        </div>
      </>
    )
  );
};
