export const priceWithSpaces = (x: any) => {
  if(!x) return '';
  const numberValue = typeof x === "string" ? Number(x) : x;
  const roundedValue =
    numberValue < 1 && numberValue > 0
      ? numberValue.toFixed(2)
      : numberValue.toFixed(0);
  const formattedValue = roundedValue.replace(/\B(?=(\d{3})+(?!\d))/g, " ");
  return `${formattedValue} ₽`;
};
