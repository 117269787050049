import { FC } from "react";
import styles from "./label.module.scss";
import { ILabel } from "@/services/types/types";
import { DeliveryIcon } from "../icon";

export const Label: FC<ILabel> = ({ text, label }) => {
  switch (label) {
    case "new":
      return <p className={styles.new}>{text}</p>;
    case "license":
      return <p className={styles.license}>{text}</p>;
    case "sale":
      return <p className={styles.sale}>{text}</p>;
    case "delivery":
      return (
        <p className={styles.delivery}>
          <DeliveryIcon className={styles.icon} />
          {text}
        </p>
      );
    case "first": {
      return <p className={styles.firstWeapon}>{text}</p>;
    }
    default:
      return;
  }
};
